import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Text } from '@pancakeswap/uikit'
import { getLanguageCodeFromLS, useTranslation } from '@pancakeswap/localization'
import { makeRequest } from 'utils/req'

const HOME = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 3px 0;
  z-index: 1;

  @media (max-width: 768px) {
    top: 56px;
  }

  .scrolling-text-wrapper {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .scrolling-text {
    display: flex;
    gap: 50px; /* 文字之间的间距 */
    animation: scrollText 20s linear infinite;
    min-width: 200%;
  }

  @keyframes scrollText {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
`

function App() {
  const [config, setConfig] = useState({
    content: '',
    link: '',
  })
  const { currentLanguage } = useTranslation()

  useEffect(() => {
    getC()
  }, [currentLanguage.locale])

  console.log(currentLanguage.locale)
  const getC = async () => {
    try {
      const result = await makeRequest(`/news/roll/get?domain=main&language=${currentLanguage.locale}`)
      console.log(result, 'result')

      const data = result.rollLine
      setConfig(data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <HOME>
      <div className="scrolling-text-wrapper">
        <div className="scrolling-text">
          {[...Array(10)].map((_, index) => (
            <a key={index} href={config.link}>
              <Text color="#fb512d">{config.content}</Text>
            </a>
          ))}
        </div>
      </div>
    </HOME>
  )
}

export default App
