import { ChainId, Token, WBNB } from '@pancakeswap/sdk'

export const polTokens = {
  WPOL: new Token(
    137,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WPOL',
    'WPOL',
    'https://pancakeswap.finance/',
  ),

  // wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 18, 'WBTC', 'Wrapped BTC'),
}
