import axios from 'axios'

export const makeRequest = async (url: string, method = 'GET', headers = {}, data = null, withCredentials = false) => {
  const fullUrl = `https://admin.gtokentool.com/admin-api${url}`
  // console.log(fullUrl, 'fullUrl')
  const options = {
    method,
    url: fullUrl,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    data, // 仅在 POST/PUT 请求时发送数据
    withCredentials,
  }

  try {
    const response = await axios(options)
    return response.data // 返回响应数据
  } catch (error) {
    // 处理错误
    console.error('Request failed', error)
    throw error // 可选择是否将错误重新抛出
  }
}
